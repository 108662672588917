.nextstepbutton {
    background-color: whitesmoke;
    border: 1px solid gray;

}

.nextstepbutton:hover {
    background-color: rgb(218, 216, 216);
    border: 1px solid gray;
    box-shadow: none;
    text-shadow: none;

}

.prevstepbutton {
    background-color: whitesmoke;
    border: 1px solid rgb(22, 20, 20);
    
}

.nextsteptext {
    background-color: blueviolet;
    /* color: white; */
}


.reactselectstyle {
    font-size: 12px;
    color: #404040;

}

    input[type="file"] {
        display: none;
    }
  
  .custom-file-upload {
    border: .5px solid #404040;
    background-color: whitesmoke;
    display: inline-block;
    padding: 10px 18px;
    cursor: pointer;
    width: 240px;
    text-align: center;
    border-radius: 5px;
    font-weight: bold;
    color: #404040;
  }

  .custom-file-upload:hover {
    border: .5px solid #404040;
    background-color: gainsboro;
    cursor: pointer;
    text-align: center;
    border-radius: 5px;

  }


  