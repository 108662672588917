.chaptercontainer{
  border: 1px solid darkgray;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 2%;
  padding-bottom: 5%;
  margin-top: 3%;
  border-radius: 0;
  margin-bottom: 5%;
  box-shadow: 2px 3px 10px lightgray;
  font-family: Georgia, Helvetica, sans-serif;
  width: 70%;
}

@media (max-width: 1200px) {
  .chaptercontainer {
      width: 90%;
      padding-top: 4%;
  }
}

@media (max-width: 700px) {
  .chaptercontainer {
      width: 100%;
  }
}


.Align {
  display: flex;
  text-align: center;
  align-items: center;
  align-self: center;
  align-content: center;
  vertical-align: center;
}

.roleSignup h5 {
  text-align: center;
  font-size: 1.5em
}

.roleSignup input,
.roleSignup button {
  font-size: 0.95em;
}

.roleSignup small{
  font-size: 0.6em;
  font-weight: 600;
  color: red !important;
  padding-left: 0.5em;
  text-align: center
}

/* .Videostyle {
  z-index: -1;
  position:fixed;
  width:100%;
  left:50%;
  top:52%;
  height:950px;
  object-fit: cover;
  transform:translate(-50%, -50%);
} */


