.contactButton {
    background: linear-gradient(to top left, #fe8d99 , #CC0000);
   
    text-shadow: #fe8d99 1px 1px 1px;
    border: none;
    color: white;
    padding: 15px;
    font-size: 17px;
    width: 250px;
    font-family: Verdana;
  }
  
  .contactButton:hover {
    background: linear-gradient(to bottom right, #f5bbc1 , #CC0000);
  
  }