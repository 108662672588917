.navsearch-results-displayed {
    max-height: 82vh;
    margin-top: 7px;
    overflow-y: scroll;
    overflow-x: hidden;
    box-shadow: 0 2px 4px 0 rgba(34, 36, 38, .30);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    scrollbar-color: red;
    position: absolute;
}

.detailsearchcontainer{ 
    background-color: white;
    text-align: left;
    padding-left: 12px;
    padding-top: 13px;
    padding-bottom: 13px;
    font-size: 1em;
    font-weight: bold;
    color: rgba(0, 0, 0, .85);
    border: none;
    min-width: 300px;
    border-top: .1px solid gainsboro;
    cursor: pointer;
    line-height: initial;
  }

  .eventsContainer {
    background-color: white;
    text-align: left;
    padding-left: 12px;
    padding-top: 13px;
    padding-bottom: 13px;
    font-size: 1em;
    font-weight: bold;
    color: rgba(0, 0, 0, .85);
    border: none;
    min-width: 300px;
    border-top: .1px solid gainsboro;
    cursor: pointer;
    line-height: initial;
  }

  .detailsearchcontainer:hover {
      background-color: whitesmoke;
  }

  .detailsearchcontainer:focus {
      background-color: whitesmoke;
  }

  div.navsearch-results-displayed::-webkit-scrollbar {
      background: lightgray;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      scrollbar-color: red;
  }

  .navsearchInput {
    border: none;
    color: #404040;
  }
  
  /* @media (max-width: 1115px) {
    .navsearchInput {
        display: none;
    }
  } */


  .navsearchInput:focus {
    outline: none;
    border: 2px solid rgb(75, 238, 250);
  }

  *::-webkit-scrollbar-track {
    background: whitesmoke;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 10px;
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: lightgray;
    border-radius: 20px;
    border: 1px solid #B8B8B8;
  }