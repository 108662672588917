.Login {
  padding: 1em;
  margin-top: 5em;
  border-radius: 5px;
  margin-left: "90%";
  background: 	#FFFFFF;
  border: 1px solid darkgray;
  box-shadow: 2px 6px 10px darkgray;
  width: 350px;
  height: 370px;
}

.loginbox {
  display: flex;
  justify-content: center;
}

.Login h5 {
  text-align: center;
  font-size: 1.5em;
}

.Login input {
  font-size: 0.95em;
  border: 1px solid #909090;
  margin-bottom: 2%;
  height: 3em;
}
.Login button {
  font-size: 0.95em;
  background-color: #E8E8E8;
}

.Login button:hover {
  font-size: 0.95em;
  color: #D0D0D0;
  background-color: #00308F;
}


#loginmenu {
  margin-left: "90%";
}

.loginmenu {
  margin-left: "90%";
}

.Login small {
  font-size: 0.6em;
  font-weight: 600;
  color: red !important;
  padding-left: 0.5em;
  text-align: center;
}
.Login small[class*='text-success'] {
  color: green !important;
}

.logintitle {
  font-family: Verdana, sans-serif;
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 10px;
  font-size: 22px;

}

.VideostyleBanner3 {
  z-index: -1;
  position:absolute;
  width:100.1%;
  left:50%;
  top:20%;
  height:1385px;
  object-fit: cover;
  transform:translate(-50%, -50%);
  
}

@media (max-width: 450px) {
  .VideostyleBanner3 {
    z-index: -1;
    position:absolute;
    width:100.1%;
    left:50%;
    top:20%;
    height:1050px;
    object-fit: cover;
    transform:translate(-50%, -50%);
    
  }
}



.testlogin {
  margin-left: 30%;
  margin-right: 30%;
  max-width: 35%;
}

@media (max-width: 1000px) {
  .testlogin {
    margin-left: 20%;
    margin-right: 20%;
    max-width: 80%;
  }
}

@media (max-width: 700px) {
  .testlogin {
    margin-left: 10%;
    margin-right: 10%;
    max-width: 80%;
  }
}

.TestLogin {
  padding: 1em;
  margin-top: 1em;
  border-radius: 20px;
  margin-left: "90%";
  background: #F8F8F8;
  border: 1px solid #696969;
  box-shadow: 2px 3px 10px black;
}


#loginbutton {
  background: linear-gradient(to bottom right, #E0E0E0	, #909090	);
  border: none;
  color: white;
  border-radius: 0px;
  width: 30%;
  margin-bottom: 20px;
  font-size: 17px;
height: 40px;
width: 120px;
text-shadow: #808080 1px 1px 1px;
}


#loginbutton:hover {
  background: linear-gradient(to top left, #B0B0B0, #909090);
  color: white;
}

.loginlinks {
  color: #004687;
  margin-bottom: 5px;
}

.logininputbox {
  padding-left: 2%;
  padding-right: 2%;
  margin-bottom: 25px;
}

