
.guestprofile{
font-family: Verdana, Georgia, Helvetica, sans-serif;
border: "1px solid #888888";
color: #606060;
display: flex;
margin: 20px;
font-size: 18px;
}


.guestprofile2{
    font-family: Verdana, Georgia, Helvetica, sans-serif;
    border: "1px solid #888888";
    color: #606060;
    display: flex;
    margin: 18px;
    font-size: 17px;
    }

.inputguest{
  font-size: 16px;
}

.innerinput{
  padding: 6px;
  border-radius: 5px;
  margin-left: 0px;
}

.edit_guest {
    color: rgb(160, 160, 160);
    font-size: 22px;
    float: right;
    /* display: flex;
    justify-content: center; */
    height: 90;
    margin-top: 8px;
  }

  .edit_guest:hover {
    color: #6565f0;
    font-size: 22px;
    float: right;
    height: 90;
  }


  .remove_guest {
    color: rgb(160, 160, 160);
    font-size: 26px;
    float: right;
    /* display: flex;
    justify-content: center; */
    height: 90;
    margin-top: 8px;
  }

  .remove_guest:hover {
    color: darkred;
    font-size: 26px;
    float: right;
    height: 90;
  }