

#contactsubmit{
        padding: .4em;
        margin-top: .5em;
        border-radius: 30px;
        background:#68adbe;
        border: 1px solid #696969;
        box-shadow: 1px 2px 5px gray;
        width: 40%;
    }

#contactsubmit:hover{
    padding: .4em;
    margin-top: .5em;
    border-radius: 30px;
    background:#4393a7;
    border: 1px solid #696969;
    box-shadow: 2px 3px 8px gray;
    width: 40%;
}

#messagaga {
    width: 140%;
}