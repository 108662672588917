.eventcreatelabels {
  text-align: left;
    font-family: Georgia, Helvetica, sans-serif;
}

.asterisk{
  color: red;
}

.createeventbutton {
  width: 250px;
}

.createeventinputs input {
border: 1px solid gray;
}

.createeventinputs select {
  border: 1px solid gray;
  }

.close_event_form_icon:hover {
  color: darkred;
  font-size: 25px;
  float: right;
  height: 80;
  margin: 90;
}

.close_event_form_icon {
  margin: "20px";
  font-size: 23px;
  float: right;
  height: 90;
  color: #505050;
  margin: 90;
}

.eventform {
  padding-top: 3%;
  padding-left: 10%;
  padding-right: 10%;
  width: 70%;
}

@media (max-width: 1000px) {
  .eventform {
    padding-top: 3%;
    padding-left: 5%;
    padding-right: 5%;
    width: 90%;
  }
}

@media (max-width: 600px) {
  .eventform {
    padding-top: 3%;
    padding-left: 5%;
    padding-right: 5%;
    width: 100%;
  }
}


@media (max-width: 430px) {
  #eventcreatebutton {
    font-size: 10px;
  }
}

@media (max-width: 480px) {
  #eventcreatebutton {
    font-size: 10px;
  }
}

@media (max-width: 480px) {
  #eventcancelbutton {
    font-size: 12px;
  }
}


