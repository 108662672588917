.officelistcontainer {
 margin-top: 2%;
margin: 20px;
  }
 

  .userReportButton {
    background: linear-gradient(to bottom right, #42b883 , #347474);
    border: none;
    color: white;
    padding: 15px;
    font-size: 15px;
    width: 220px;
    font-family: Verdana;
    margin-top: 3%;
    margin-left: 5%;
    margin-bottom: 1%;
  }
  
  .userReportButton:hover {
    background: linear-gradient(to top left, #42b883 , #347474);
    border: none;
    color: white;
    padding: 15px;
    font-size: 15px;
    font-family: Verdana;
  }