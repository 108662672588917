@import url('https://fonts.googleapis.com/css?family=Fira+Sans')
/*Variables*/
$background_color: #353b48
$font: "Fira Sans", sans-serif
$white: #f5f6fa
$black: #0C0E10
$gray: #202425
$blue: #446182
//Mixins
=smallscreens
    @media (max-width: 770px)
        @content
//Extends
%Ycenter
    position: absolute
    top: 50%
    transform: translateY(-50%)
//Main Layout Styles
.background404
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background: linear-gradient($black, $blue)
    .ground
        position: absolute
        bottom: 0
        width: 100%
        height: 25vh
        background: $black
        +smallscreens
            height: 0vh

.container404
    position: relative
    margin: 0 auto
    width: 85%
    height: 100vh
    padding-bottom: 25vh
    display: flex
    flex-direction: row
    justify-content: space-around
    +smallscreens
        flex-direction: column
        padding-bottom: 0vh
.left-section, .right-section
    position: relative
//Left Section Styles
.left-section
    width: 40%
    +smallscreens
        width: 100%
        height: 40%
        position: absolute
        top: 0
    .inner-content
        @extend %Ycenter
        +smallscreens
            position: relative
            padding: 1rem 0
.heading
    text-align: center
    font-size: 9em
    line-height: 1.3em
    margin: 2rem 0 0.5rem 0
    padding: 0
    text-shadow: 0 0 1rem #fefefe
    +smallscreens
        font-size: 7em
        line-height: 1.15
        margin: 0
.subheading
    text-align: center 
    max-width: 480px
    font-size: 1.5em
    line-height: 1.15em
    padding: 0 1rem 
    margin: 0 auto
    +smallscreens
        font-size: 1.3em
        line-height: 1.15
        max-width: 100%
//Right Section Styles
.right-section
    width: 50%
    +smallscreens
        width: 100%
        height: 60%
        position: absolute
        bottom: 0
.svgimg
    position: absolute
    bottom: 0
    padding-top: 10vh
    padding-left: 1vh
    max-width: 100%
    max-height: 100%
    +smallscreens
        padding: 0
    .bench-legs
        fill: $black
    .top-bench, .bottom-bench
        stroke: $black 
        stroke-width: 1px
        fill: #5B3E2B
    .lamp-details
        fill: $gray
    .lamp-bottom
        fill: linear-gradient($gray, $black)
    .lamp-light
        fill: #EFEFEF
@keyframes glow
    0%
        text-shadow: 0 0 1rem #fefefe
    50% 
        text-shadow: 0 0 1.85rem #ededed
    100%
        text-shadow: 0 0 1rem #fefefe