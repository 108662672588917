

.cardelementcontainer {
    margin-top: 2%;
    margin-left: .5%;

}

.addguestbutton {
    margin-left: 15px;
    margin-right: -12px;
    width: 200px;
}


@media (max-width: 500px){
    .addguestbutton {
        width: 130px;
    
    }
    }


@media (max-width: 400px){
    .addguestbutton {
        width: 120px;
        font-size: 12px;
    }
    }


    .guestnumber {
        color: #A0A0A0;
        text-shadow: 1px 1px 0px black;
        /* border-bottom: .9px solid dimgray; */
    }
  

@media (max-width: 700px) {
    .guestnumber {
        font-size: 0;
    }
  }


@media (max-width: 900px) {
    .cardelementcontainer {
        width: 99%;
        margin-top: 2%;
        margin-left: .5%;
    }
  }


@media (max-width: 400px) {
.cardelementcontainer {
    width: 99%;
    margin-top: 2%;
    margin-left: .5%;
}
}

  