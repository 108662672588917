.profilecontainer{
    border: 1px solid darkgray;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 2%;
    padding-bottom: 5%;
    margin-top: 5%;
    border-radius: 10px;
    margin-bottom: 5%;
    box-shadow: 2px 3px 10px lightgray;
    font-family: Verdana Georgia Helvetica sans-serif;
    width: 70%;

}
.verifyemailcontainer{
  border: 1px solid darkgray;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 2%;
  padding-bottom: 5%;
  margin-top: 3%;
  border-radius: 0;
  margin-bottom: 5%;
  box-shadow: 2px 3px 10px lightgray;
  font-family: Georgia, Helvetica, sans-serif;
  width: 50%;
}

.mailsettingscontainer{
  border: 1px solid darkgray;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 2%;
  padding-bottom: 5%;
  margin-top: 3%;
  border-radius: 0;
  margin-bottom: 5%;
  box-shadow: 2px 3px 10px lightgray;
  font-family: Georgia, Helvetica, sans-serif;
  width: auto;
}


.profilecontainer_edit {
    border: 1px solid darkgray;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 2%;
    padding-bottom: 2%;
    margin-top: 5%;
    border-radius: 25px;
    margin-bottom: 5%;
    box-shadow: 2px 3px 10px lightgray;
    font-family: Georgia, Helvetica, sans-serif;
  width: 70%
}


.volunteerscontainer{
    text-align: center;
    border: 1px solid darkgray;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 2%;
    margin-top: 5%;
    border-radius: 25px;
    height: 200px;
    margin-bottom: 5%;
    box-shadow: 2px 3px 10px lightgray;
    font-family: Georgia, Helvetica, sans-serif;
    width: 50%;
}

@media (max-width: 1200px) {
    .volunteerscontainer {
        width: 80%;
    }
  }
  
  @media (max-width: 750px) {
    .volunteerscontainer {
        width: 90%;
    }
  }


  @media (max-width: 500px) {
    .volunteerscontainer {
        width: 90%;
    }
  }


@media (max-width: 1200px) {
    .profilecontainer {
        width: 90%;
        padding-top: 4%;
    }
  }
  
  @media (max-width: 700px) {
    .profilecontainer {
        width: 100%;
    }
  }

  @media (max-width: 1200px) {
    .verifyemailcontainer {
        width: 90%;
        padding-top: 4%;
    }
  }
  
  @media (max-width: 700px) {
    .verifyemailcontainer {
        width: 100%;
    }
  }


  @media (max-width: 1200px) {
    .profilecontainer_edit {
        width: 90%;
        padding-top: 4%;
    }
  }
  
  @media (max-width: 700px) {
    .profilecontainer_edit {
        width: 100%;
    }
  }

.profiletitlefont {
    font-family: Verdana, Geneva, Tahoma, sans-serif, Helvetica;
    font-size: 30px;
    color: #606060;
}
.profiletitlesub{
    font-family: Verdana, Helvetica, sans-serif;
    font-size: 24px;
    color: #404040;
}

.deleteaccounttitle{
  font-family: Verdana, Helvetica, sans-serif;
  font-size: 21px;
  color: #404040;
}

.changepasstitle{
  font-family: Verdana, Helvetica, sans-serif;
  font-size: 21px;
  color: #404040;
}

.profilefont {
    font-family: Verdana, Geneva, Tahoma, sans-serif, Helvetica;
}

#profilefontinput{
    font-family: Verdana, Geneva, Tahoma, sans-serif, Helvetica;
    border: "1px solid #888888";
    color: #606060;
}

.profilefontinput{
    font-family: Verdana, Geneva, Tahoma, sans-serif, Helvetica;
    border: "1px solid #888888";
    font-size: 18px;
    color: #606060;
}


  .subscribebutton {
    color: white;
    border: 1px solid #00308F;
    border-radius: 5px;
    width: 320px;
    margin-bottom: 2%;
    box-shadow: 6px 6px 6px lightgray;
    font-size: 22px;
    height: 55px;
    background-color: #0077c0;
  }

  .subscribebutton:hover {
    color: white;
    border: 1px solid black;
    background-color: #0167a7;
    box-shadow: 6px 6px 6px lightgray;
  }

  .subscribebutton:disabled {
    /* background: #7d7e7e; */
    opacity: 40%;
    background-color: #1da799;
  }

  .subscribebutton:hover:disabled {
    /* background: #7d7e7e; */
    opacity: 40%;
    background-color: #1da799;
  }

  .deletebutton {
    color: white;
    border: 1px solid #00308F;
    border-radius: 5px;
    width: 320px;
    margin-bottom: 2%;
    box-shadow: 6px 6px 6px lightgray;
    font-size: 22px;
    height: 55px;
    background-color: #DE3163;
    padding-left: 10px;
    padding-right: 10px;
  }


  .deletebutton:hover {
    background-color: #b1224b;
  }



  .verifybutton {
    color: white;
    border: 1px solid #00308F;
    border-radius: 5px;
    width: 320px;
    margin-bottom: 2%;
    box-shadow: 6px 6px 6px lightgray;
    font-size: 22px;
    height: 55px;
    background-color: #0077c0;
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .verifybutton:hover {
    color: white;
    background-color: #00308F;
    border: 1px solid black;
    background-color: #188177;
    box-shadow: 6px 6px 6px lightgray;
  }

  .verifybutton:disabled {
    /* background: #7d7e7e; */
    opacity: 40%;
    background-color: #1da799;
  }

  .verifybutton:hover:disabled {
    /* background: #7d7e7e; */
    opacity: 40%;
    background-color: #1da799;
  }


  .profileimage {
    width: 20%;
}

@media (max-width: 1000px) {
    .profileimage {
        width: 25%;
    }
  }
  
  @media (max-width: 700px) {
    .profileimage {
        width: 30%;

    }
  }


  @media (max-width: 1000px) {
    .verifybutton {
        width: 310px;
    }
  }

  @media (max-width: 800px) {
    .subscribebutton {
        width: 310px;

    }
  }


  .profileinput{
      margin-left: 1%;
      border-radius: 5px;
      padding-left: 7px;
      width: 270px;
  }

  .edit_profile {
    color: rgb(160, 160, 160);
    font-size: 22px;
    float: right;
    height: 90;
    margin-top: 8px;
  }


  .edit_profile:hover {
    color: #6565f0;
    font-size: 22px;
    float: right;
    height: 90;
  }
  

  .edit_profile_remove {
    color: rgb(160, 160, 160);
    font-size: 24px;
    float: right;
    height: 90;
    margin-top: 8px;
  }


  .edit_profile_remove:hover {
    color: darkred;
    font-size: 24px;
    float: right;
    height: 90;
  }

  .edit_pic {
    color: rgb(160, 160, 160);
    font-size: 18px;
    height: 90;
    color: rgb(160, 160, 160);
    margin-bottom: 30;
  }


  .edit_pic:hover {
    color: darkred;
    font-size: 19px;
    height: 90;
  }
  

  .changepassprofile {
    width: 50%;
  }

  .changepassprofile {
    width: 50%;
  }

  @media (max-width: 1200px) {
  .changepassprofile {
    width: 80%;
  }
}