.RoleSignup {
    background: rgb(240, 240, 240, 0.95);
    padding: 1em;
    margin-top: 0em;
    border-radius: 4px;
    border: 1px solid black;
  }
  
  .Align {
    display: flex;
    text-align: center;
    align-items: center;
    align-self: center;
    align-content: center;
    vertical-align: center;
  }
  
  .RoleSignup h5 {
    text-align: center;
    font-size: 1.5em
  }
  
  .RoleSignup input,
  .RoleSignup button {
    font-size: 0.95em;
  }
  
  .RoleSignup small{
    font-size: 0.6em;
    font-weight: 600;
    color: red !important;
    padding-left: 0.5em;
    text-align: center
  }
  
  /* .Videostyle {
    z-index: -1;
    position:fixed;
    width:100%;
    left:50%;
    top:52%;
    height:950px;
    object-fit: cover;
    transform:translate(-50%, -50%);
  } */
  
  
  .roleSignup {
    padding: 1em;
    margin-top: 3em;
    border-radius: 20px;
    margin-left: "90%";
    border: 1px solid gray;
    box-shadow: 2px 3px 10px darkgray;
    }
  
  .volunteerResetPassword {
  border: none;
  margin-left: 10px;
  color: rgb(160, 160, 160);
    }
  
    .volunteerResetPassword:hover {
      border-radius: 10px;
      border: 1px solid lightgray;
      margin-left: 10px;
      color: rgb(76, 177, 56);
        }
  
  
        .edit_volunteers_remove {
          color: rgb(160, 160, 160);
          font-size: 16px;
          float: right;
          padding-left: 10px;
          padding-right: 4px;
        }
      
        .edit_volunteers_remove:hover {
          color: darkred;
          font-size: 16px;
          float: right;
        }