

.eventdetails {
    font-size: 14px;
}

@media (max-width: 430px) {
    .eventdetails {
      font-size: 11px;
    }
  }