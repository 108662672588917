@import url('https://fonts.googleapis.com/css?family=Josefin+Sans+Futura+Raleway+Lato+Montserrat+Roboto+Alegreya');
.landingheadings {
  font-family: 'Lato';
  font-weight: bold;
}

.innerdivheadings {
  font-family: 'Lato';
}

.innerdivtitles {
  font-family: 'Futura';
}


.App {
  position: absolute;
  width: 100%;
  /* background: url('./background.png') no-repeat fixed; */
  background-size: cover;
  background-position: center;
}

.arrow:hover {
  color: #606060 !important;
  cursor: pointer;
} 






















