@import url('https://fonts.googleapis.com/css?family=Josefin+Sans+Futura+Raleway+Lato+Montserrat+Roboto+Alegreya');
.agree_terms {
  font-family: 'Lato';
  font-weight: bold;
}
  
  
  .Signup {
  padding: 20px;
  margin-top: 1em;
  border-radius: 5px;
  margin-left: "90%";
  background: 	#FFFFFF;
  border: 1px solid darkgray;
  box-shadow: 2px 6px 10px darkgray;
    }
  
    .Align {
      display: flex;
      text-align: center;
      align-items: center;
      align-self: center;
      align-content: center;
      vertical-align: center;
    }
    
    .Signup h5 {
      text-align: center;
      font-size: 1.5em;
    }
    
    .Signup input {
      font-size: 0.95em;
      border: 1px solid #909090;
      margin-bottom: 2%;
      height: 2.5em;
    }
    .Signup button {
      font-size: 0.95em;
      background-color: #E8E8E8;
    }
    
    .Signup button:hover {
      font-size: 0.95em;
      color: #D0D0D0;
      background-color: #00308F;
    }

    .signuptitle {
      font-family: Verdana, sans-serif;
      font-weight: bold;
      margin-bottom: 20px;
      margin-top: 10px;
      font-size: 24px;
    }


  .VideostyleBanner2 {
    z-index: -1;
    position:absolute;
    width:100.1%;
    left:50%;
    top:20%;
    height:1600px;
    object-fit: cover;
    transform:translate(-50%, -50%);
  }


  @media (max-width: 800px) {
    .VideostyleBanner2 {
      z-index: -1;
      position:absolute;
      width:100.1%;
      left:50%;
      top:20%;
      height:1200px;
      object-fit: cover;
      transform:translate(-50%, -50%);
    }
  }
  
  @media (max-width: 450px) {
    .VideostyleBanner2 {
      z-index: -1;
      position:absolute;
      width:100.1%;
      left:50%;
      top:20%;
      height:1100px;
      object-fit: cover;
      transform:translate(-50%, -50%);
    }
  }



  #signupbutton {
    background: linear-gradient(to bottom right, #E0E0E0	, #727171	);
    border: none;
    color: white;
    border-radius: 0px;
    width: 30%;
    margin-bottom: 20px;
    font-size: 17px;
  height: 40px;
  width: 120px;
  text-shadow: #808080 3px 1px 3px;
  }
  
  #signupbutton:hover {
    background: linear-gradient(to top left, #c2c1c1, #909090);
    color: white;
  }

  #signupbutton:disabled {
    background: linear-gradient(to top left, rgb(177, 176, 176), gainsboro);
    
  }

  .signupbox {
    margin-left: 30%;
    margin-right: 30%;
    max-width: 35%;
  }
  
  @media (max-width: 1000px) {
    .signupbox {
      margin-left: 20%;
      margin-right: 20%;
      max-width: 80%;
    }
  }
  
  @media (max-width: 700px) {
    .signupbox {
      margin-left: 10%;
      margin-right: 10%;
      max-width: 80%;
    }
  }

  option[default] {
    display: none;
  }




  .join-login-form {
  background-color: #202027;
  width: 50vw;
  max-width: 50%;
  padding: 2rem 4rem;
  border-radius: 20px;
}

.input-group {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}
.input-group label{
  color : #fff;
  font-family : tahoma;
}
.input-group span{
  color : #888;
  font-family : tahoma;
  font-size: 11px;
}
.input-icon-container{
  display: flex;
  flex-direction: row;
  border: 1px solid #374669;
  border-radius: 5px;
  background : #fff;
  align-items : center;
  padding-top: 2px;
  overflow: hidden;
  height: 35px !important;
}
.input-icon-container.error{
  border: 1px solid red;
  background : #fdd3d3;
}
.input-icon-container input {
  outline: none;
  border: none;
  background: none;
  font-size: 1em;
  padding: .5em;
  color: inherit;
  flex : auto 1 1;
  width : 100%;
  background : none;
  background-color : transperant;
}

.input-icon-container .fa{
  padding : 10px;
}

.fa.fa-user{
  background : #374669;
  color: #fff;
}
