

.eventlistah {
    font-size: 14px;
}

@media (max-width: 430px) {
    .eventlistah {
      font-size: 11px;
    }
    
  }

  .reportButton {
    font-size: 0.95em;
    color: black;
    background-color: whitesmoke;
    border-radius: 12px;
    box-shadow: 1px 2px 4px darkgray;
    padding: 2%;
    margin-left: 1%;
    margin-top: 1%;
  }



.reportButton:hover {
  border-radius: 12px;
  color: black;
  background-color: rgb(231, 231, 231);
  box-shadow: 1px 2px 4px darkgray;
  padding: 2%;
  margin-left: 1%;
  margin-top: 1%;
}