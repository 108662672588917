

.prTableCell {
    padding: 7px;
    width: 60px;
    /* TEXT ALIGN CENTER WILL MAKE IT SO TEXT DOES NOT WRAP */
    /* text-align: center; */
    /* border: 1px solid #e1e1e1; */
    padding-left: 1%;
    cursor: pointer;
  }



  .prTableHeaderFields {
  font-size: 14px;
  min-width: 165px;
  }

  .prTableHeader0{
    min-width: 35px;
  }

  @media (max-width:1000px) {
    .prTableHeader0{
      min-width: 35px;
    }
  }

  @media (max-width:800px) {
  .prTableHeader0{
    min-width: 30px;
  }
}

  @media (max-width: 1300px) {
    .prTableHeaderFields {
      min-width: 150px;
    }
  }
  

  @media (max-width: 1200px) {
    .prTableHeaderFields {
      min-width: 130px;
    }
  }

  @media (max-width:1000px) {
    .prTableHeaderFields {
      min-width: 95px;
    }
  }

  @media (max-width:800px) {
    .prTableHeaderFields {
      min-width: 90px;
    }
  }

  @media (max-width: 700px) {
  .prTableHeaderFields {
    min-width: 80px;
  }
}
  
  .prExerVariNameTD {
    width: 200px;
    border: 1px solid #e1e1e1!important;
    padding: 5px 5px 5px 10px;
    background-color: white;
  }
  
  .prVerticalSpace {
    width: 50px;
  }
  
  .prTableCell:nth-child(even) {
    background-color: #F8F8F8
  }

  .prTableCell:hover:nth-child(even) {
    background-color: #fff;
  }

  .prTableCell:hover  {
    background-color: #fff;
  }
  
  .prTableCell:nth-child(odd) {
    background-color: #fff;
  }
  
  .prTableRow:hover .prTableCell:nth-child(even) {
    background-color: #E8E8E8
  }
  
  .prTableRow:hover .prTableCell:nth-child(odd) {
    background-color: #eee;
  }

  .guestcheckboxcontainer {
    border-bottom: .9px solid darkgray
  }

  #wow {
    background-color: black;
  }

 #guestcheckbox{
    width: 17px;
    height: 17px;
    color: aqua;;
    margin-top: 10px;
    accent-color: #909090	;
 }

 .volunteerResetPasswordPen{
     color: #909090;
     margin-left: 10px;
     padding-right: 10px;
 }

 /* #dropdownactions{
   border: 1px solid darkgray;
   width: 100px;
   background-color:#D0D0D0;
   margin-top: 1px;
   border-radius: 0px;
   font-family: Verdana, Geneva, Tahoma, sans-serif;
   font-size: 13px;
   margin-right: 2px;
 } */

 #dropdownactions:hover{
  background-color:#BEBEBE	;
}

 .guestlistcontainer {
   margin-top: .4%;
 }

 #dropdownactionslist{
  margin-top: 1px;
  margin-right: 2px;
   color: #505050;
  border: .1px solid #D0D0D0;
  background-color:#D0D0D0;
  border-radius: 0;
   font-size: 13.5px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  border: 1px solid #BEBEBE;
  
 }

 #hidecheckin{
  margin-top: 1px;
  margin-right: 2px;
   color: #505050;
  border: .1px solid #D0D0D0;
  background-color:#D0D0D0;
  border-radius: 0;
   font-size: 13.5px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  border: 1px solid #BEBEBE;
  width: 170px;
 }

 #hidecheckin:hover{
  background-color:#C0C0C0	;
 }

 @media (max-width: 600px) {
 #hidecheckin{
  width: 140px;
  font-size: 12px;
  padding: 7px;
 }
 }

 @media (max-width: 500px) {
  #hidecheckin{
display: none;
  }
  }

 @media (max-width: 600px) {
 #dropdownactionslist{
  margin-top: 1px;
  margin-right: 2px;
   color: #505050;
  border: .1px solid #D0D0D0;
  background-color:#D0D0D0;
  border-radius: 0;
   font-size: 13.5px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  border: 1px solid #BEBEBE;
  font-size: 12px;
  padding: 7px;
 }
 }

 #dropdownactionslist:hover{
  background-color:#C0C0C0	;
 }


 #dropdownactionstoggle{
  margin-top: 1px;
   color: #505050;
  border: .5px solid #D0D0D0;
  background-color:#D0D0D0;
  border-radius: 0;
   font-size: 13.5px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  margin-right: 2px;
  width: 100px;
 }

 #dropdownactions1:hover{
  background-color:#BEBEBE	;
 }
 

 @media (max-width: 1200px) {
  .guestlistcontainer {
    margin-top: .6%;
  }
}


@media (max-width: 900px) {
  .guestlistcontainer {
    margin-top: .8%;
  }
}

@media (max-width: 700px) {
  .guestlistcontainer {
    margin-top: 1%;
  }
}