.viewTicketButton{
color: DimGray;
border: 1px solid DimGray;
border-radius: 15px;
width: 30%;
margin-bottom: 2%;
margin-top: 4%;
margin-left: 3%;
background-color: #eeeded;
}

.viewTicketButton:hover{
    color: black;
    border: 1px solid black;
    background-color: #dbd8d8;
    border-radius: 15px;
    width: 30%;
    margin-bottom: 2%;
    margin-top: 4%;
    margin-left: 3%;
    }

    .viewTicket{
        color: DimGray;
        border: 1px solid DimGray;
        border-radius: 15px;
        width: 30%;
        background-color: #eeeded;
        margin-top: 2%;
        }
        
        .viewTicket:hover{
            color: black;
            border: 1px solid black;
            background-color: #dbd8d8;
            border-radius: 15px;
            width: 30%;
            margin-top: 2%;
            }