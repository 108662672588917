

  .gobackButton {
    background-color: #F8F8F8;
    box-shadow: 1px 2px 5px darkgray;
  }

  .gobackButton:hover {
    background: #e08f8f;
    box-shadow: 1px 2px 5px darkgray;
  }

  #inviteMessage{
    width: 50%;
    height: 150px;
  }

  #inviteSignature{
    width: 50%;
    height: 110px;
  }

  #messageSubject{
    width: 70%;
  }

  #inviteSalutation {
    width: 70%;
  }

  #invitePreview{
    width: 80%;
    min-height: 550px;
    height: auto !important;
    box-shadow: 1px 1px 1px 1px darkgray;
  }

  .messageContainer {
    margin-left: 5%;
  }


  div {
    white-space: pre-wrap;
}

.ql-editor {
  height: 350px !important;
}

.save_and_continue {
  width: "32%",
}

@media (max-width: 700px) {
  .save_and_continue {
    width: "42%",
  }
  }