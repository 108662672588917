#searchboxdashboard {
    
    font-size: 14px;
    border: 1px solid black;
    margin-top: 1px;
    width: 180%;
}

@media (max-width: 1200px) {
    #searchboxdashboard {
        width: 170%;
        font-size: 13px;
    }
  }



@media (max-width: 900px) {
    #searchboxdashboard {
        width: 160%;
        font-size: 13px;
    }
  }
  @media (max-width: 600px) {
    #searchboxdashboard {
        width: 160%;
        font-size: 12px;
    }
  }

  @media (max-width: 500px) {
    #searchboxdashboard {
        width: 155%;
        font-size: 11px;
    }
  }

  @media (max-width: 470px) {
    #searchboxdashboard {
        width: 150%;
        font-size: 10px;
    }
  }

  @media (max-width: 400px) {
    #searchboxdashboard {
        width: 155%;
        font-size: 11px;
    }
  }


  @media (max-width: 350px) {
    #searchboxdashboard {
        width: 140%;
        font-size: 11px;
    }
  }

  @media (max-width: 320px) {
    #searchboxdashboard {
        width: 110%;
        font-size: 11px;
    }
  }