.chaptercontainer{
    border: 1px solid darkgray;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 2%;
    padding-bottom: 5%;
    margin-top: 3%;
    border-radius: 0;
    margin-bottom: 5%;
    box-shadow: 2px 3px 10px lightgray;
    font-family: Georgia, Helvetica, sans-serif;
    width: 70%;
  }

  @media (max-width: 1200px) {
    .chaptercontainer {
        width: 90%;
        padding-top: 4%;
    }
  }
  
  @media (max-width: 700px) {
    .chaptercontainer {
        width: 100%;
    }
  }

.createchapterbutton {
    color: white;
    border: 1px solid #00308F;
    border-radius: 5px;
    margin-bottom: 2%;
    box-shadow: 6px 6px 6px lightgray;
    font-size: 20px;
    height: 55px;
    background-color: #0077c0;
    padding-left: 10px;
    padding-right: 10px;
  }

  .createchapterbutton:hover {
    color: white;
    border: 1px solid black;
    background-color: #0167a7;
    box-shadow: 6px 6px 6px lightgray;
  }

  @media (max-width: 430px) {
  .createchapterbutton {
    color: white;
    border: 1px solid #00308F;
    border-radius: 5px;
    margin-bottom: 2%;
    box-shadow: 6px 6px 6px lightgray;
    font-size: 20px;
    height: 70px;
    background-color: #0077c0;
    padding-left: 10px;
    padding-right: 10px;
  }
  }


  .chapters_field{
    width: 363px
  }

  .description_field{
    width: 363px
  }





.chapterTableCell {
    padding: 7px;
    /* TEXT ALIGN CENTER WILL MAKE IT SO TEXT DOES NOT WRAP */
    /* text-align: center; */
    /* border: 1px solid #e1e1e1; */
    padding-left: 1%;
    cursor: pointer;
  }


  .prTableHeaderFields {
  font-size: 14px;
  min-width: 165px;
  }


  @media (max-width: 1300px) {
    .prTableHeaderFields {
      min-width: 150px;
    }
  }
  

  @media (max-width: 1200px) {
    .prTableHeaderFields {
      min-width: 130px;
    }
  }

  @media (max-width:1000px) {
    .prTableHeaderFields {
      min-width: 95px;
    }
  }

  @media (max-width:800px) {
    .prTableHeaderFields {
      min-width: 90px;
    }
  }

  @media (max-width: 700px) {
  .prTableHeaderFields {
    min-width: 80px;
  }
}
  
  .prExerVariNameTD {
    width: 200px;
    border: 1px solid #e1e1e1!important;
    padding: 5px 5px 5px 10px;
    background-color: white;
  }
  
  .prVerticalSpace {
    width: 50px;
  }
  
  .chapterTableCell:nth-child(even) {
    background-color: #F8F8F8
  }

  .chapterTableCell:hover:nth-child(even) {
    background-color: #fff;
  }

  .chapterTableCell:hover  {
    background-color: #fff;
  }
  
  .chapterTableCell:nth-child(odd) {
    background-color: #fff;
  }
  
  .prTableRow:hover .chapterTableCell:nth-child(even) {
    background-color: #E8E8E8
  }
  
  .prTableRow:hover .chapterTableCell:nth-child(odd) {
    background-color: #eee;
  }