@import url('https://fonts.googleapis.com/css?family=Josefin+Sans+Futura+Raleway+Lato+Montserrat+Roboto+Alegreya');

.footerheading{
  font-family: 'Lato';
  
}

html,
body {
  height: 100%;
  font-family: 'Futura';
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  flex: 1 0 auto;
  padding: 10px;
}

.footer {
  flex-shrink: 0;
  text-align: center;
  background-color: #343A40;
  color: white;
}
