.NavHeader {
  z-index: 10;
  width: 100%;
  top: 0;
  height: 1%;
  max-height: 1%;
  /* background: url('../../background.png') no-repeat fixed; */
}

.navbar-header{
  height: 50px;
}

.Header {
  z-index: 10;
  width: 100%;
  border: 1px solid black;
}

.Header .NavBk {
  /* border-radius: 4px; */
}

.Header a {
  margin-left: .7em;
  font-weight: 700
}

.Header .addBtn,
.Header button[class='navbar-toggler'] {
  margin-right: 0.8em;
  color: black;
}

.Navs{
  position: absolute;
  right: 1em;
  float: right;
}

.Header .addBtn input,
.Header .addBtn button {
  font-size: 0.91em
}

.Header nav button[class*='add'] {
  width: 6em
}

.nav-item {
  font-family: Helvetica, sans-serif;
}


#navdropdowncontrol{
  margin-left: 0px;
  margin-right: -18px;
}

.togglernav {
  color: #999D9F
}

.togglernav:hover {
  color: #bdbebe
}

@media (max-width: 998px) {
  #fullsize{
  display: none;
    }
  }

  @media (max-width: 998px) {
    .fullsize{
    display: none;
      }
    }

@media (min-width: 999px) {
  #halfsize{
  display: none;
    }
  }

@media (max-width: 990px) {
#navdropdowncontrol{
  margin-left: -6px;
  margin-top: -12px;
  margin-right: 0px
}
}

@media (min-width: 701px) {
.unloggednavbar_half{
  display: none;
  }
}

@media (max-width: 992px) {
  .lastnavitem{
    margin-right: 22px;
    }
  }

@media (max-width: 992px) {
.unloggednavbar_full{
  margin-left: 14px;
}
}

@media (max-width: 700px) {
  .unloggednavbar_full{
    display: none;
    }
  }

@media (max-width: 400px) {
.globalguestsearch{
  display: none;
 }
}

@media (max-width: 991px) {
  .collapsefixunlogged{
    margin-left: 15px
  }
  }

@media (max-width: 991px) {
  .collapsefixunloggedlogin{
    margin-left: 15px;
    margin-right: 19px;
  }
  }



  .green {
    color: green;
  }
  .red {
    background-color: yellow;
  }

  .highlight {
    background-color: yellow;
  }


  @media (max-width: 400px) {
  .navSearchDiv {
    display: none;
  }
}

@media (max-height: 400px) {
.shortMenuBar{
  overflow-y: scroll;
}
}

.shortNavBarCSSMobile {
  min-height: 15px !important;
}